import "./src/styles/global.scss";
import "./src/styles/index/hero.scss";
import "./src/styles/index/description.scss";
import "./src/styles/index/waiting-list-form.scss";
import "./src/styles/index/coming-soon.scss";
import "./src/styles/common/buttons.scss";
import "./src/styles/common/inputs.scss";
import "./src/styles/layout/header.scss";
import "./src/styles/layout/footer.scss";
import 'aos/dist/aos.css';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
